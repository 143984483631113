<template>
  <div class="container">
    <img class="bg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/bg.png" >
    <div class="container-cont">
      <div class="scroll_tips">
      <div class="scroll_box">
        <span class="scroll_item">176****6142抢到了</span><span class="scroll_item">131****7394抢到了</span><span
          class="scroll_item">155****4549抢到了</span><span class="scroll_item">130****5436抢到了</span><span
          class="scroll_item">156****0054抢到了</span><span class="scroll_item">171****5245抢到了</span><span
          class="scroll_item">175****6540抢到了</span><span class="scroll_item">185****0974抢到了</span>
      </div>
    </div>
    <div class="form">
      <van-field v-model.trim="phone" type="tel" @input="phoneChange" maxlength="11" placeholder="请输入手机号" />
      <img class="submit" @click="phoneChange"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/qiuMoFusion/submit.png" />
      <van-checkbox checked-color="#ee0300" v-model="checked" icon-size="16px">
        我已阅读并同意<u class="book" @click.stop="showDialog">隐私条款</u>
      </van-checkbox>
    </div>
    </div>
  </div>
</template>

<script>
import { hnPhoneCheck } from "@/api/qiuMoFusion/index";
import { embedding } from "@/api/utils.js";
import tailJumpMixin from "@/utils/tailJump.js";
import { JuDuoDuo } from "@/utils/common";
import performance from "@/mixin/performance.js";

export default {
  mixins: [tailJumpMixin, performance],
  data() {
    return {
      phone: "",
      productNo: "",
      checked: false,
    };
  },
  methods: {
    async phoneChange() {
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.phone)) return;
      this.$toast({
        message: "正在为您挑选合适产品~",
      });
      const { data = "" } = await hnPhoneCheck(this.phone);
      if(!data || data == "") return;
      try {
        if (process.env.NODE_ENV !== "development") {
          await embedding({embeddingCode: "o.phone", clickNum: '1', form: 'QIUMORONGHE', phone: this.phone, phoneProvince: data });
        }
      } finally {
        const ip = location.origin + "/pages";
        if (data.indexOf("联通") >= 0) {
          if (data.indexOf("浙江") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/LTZheJiang/videoRingtone25?from=QIUMORONGHE&phone=${this.phone}`
            );
          // } else if (data.indexOf("安徽") >= 0) {
          //   window.location.replace(
          //     `${ip}/qiuMoFusion/LTAnHui/videoRingtone26?from=QIUMORONGHE&phone=${this.phone}`
          //   );
          } else if (data.indexOf("四川") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/LT/sichuan/pandaAI?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else if (data.indexOf("湖北") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/LT/hubei/package20?from=QIUMORONGHE&phone=${this.phone}`
            );
          // } else if (data.indexOf("上海") >= 0) {
          //   window.location.replace(
          //     `${ip}/qiuMoFusion/LT/shanghai/videoRingtone25?from=QIUMORONGHE&phone=${this.phone}`
          //   );
          } else {
            // 其它省份联通号码
            JuDuoDuo("100023", this.phone, "热门会员立即领取");
          }
        }
        if (data.indexOf("电信") >= 0) {
          if (data.indexOf("浙江") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/DXZheJiang/specialOffer29?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else if (data.indexOf("海南") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/DXHaiNan/29.9yuan30GB?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else if (data.indexOf("安徽") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/DXAnHui/39yuan20GB?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else if (data.indexOf("云南") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/DX/yunnan/assemble19.9?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else {
            // 其它省份电信号码
            window.location.replace(
              `${ip}/qiuMoFusion/ALL/dx_fusion?from=QIUMORONGHE&phone=${this.phone}`
            );
          }
        } else if (data.indexOf("移动") >= 0) {
          if (data.indexOf("海南") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/YDHaiNan/5GPremiumPackage?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else if (data.indexOf("广西") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/YDGuangXi/YoukuWatch?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else if (data.indexOf("河南") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/YD/henan/5Gpackage4?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else if (data.indexOf("重庆") >= 0) {
            window.location.replace(
              `${ip}/qiuMoFusion/YD/chongqing/chaoyou?from=QIUMORONGHE&phone=${this.phone}`
            );
          } else {
            // 其它省份移动号码
            window.location.replace(
              `${ip}/qiuMoFusion/ALL/yd_fusion?from=QIUMORONGHE&phone=${this.phone}`
            );
          }
        }
      }
    },
    showDialog() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "个人信息保护授权协议",
        message: `尊敬的客户：
您好！
根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
（1）事先获得客户的明确授权；
（2）根据有关的法律法规要求；
（3）按照相关司法机关和/或政府主管部门的要求；
（4）为维护社会公众的利益所必需且适当；
（5）为维护我公司或客户的合法权益所必需且适当。`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #ff8f47;
  min-height: 100%;
  overflow: auto;
  padding-top: 1023px;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 100px;

  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &-cont {
    position: relative;

    .scroll_tips {
      width: 100%;
      // margin: 20px auto 0;
      overflow: hidden;

      .scroll_box {
        height: 61px;
        animation: scroll 15s linear infinite;
        display: flex;

        .scroll_item {
          background-color: rgba(0, 0, 0, 0.44);
          border-radius: 40px;
          white-space: nowrap;
          width: 329px;
          text-align: center;
          font-weight: 500;
          flex-shrink: 0;
          line-height: 61px;
          font-size: 30px;
          color: white;
          margin-left: 62px;
        }

        @keyframes scroll {
          0% {
            transform: translateX(0%);
          }

          100% {
            transform: translateX(-317.2%);
          }
        }
      }
    }

    .form {
      ::v-deep .van-cell {
        width: 502px;
        height: 80px;
        background-color: #ffffff;
        border-radius: 60px;
        margin: 60px auto 0;
        overflow: hidden;
        box-sizing: border-box;

        &.input-code {
          margin-top: 45px;

          .code {
            width: 229px;
            text-align: center;
            color: #e13449;
            font-size: 30px;
            border-left: solid 3px #e13449;
          }
        }

        .van-field__body {
          height: 100%;

          input {
            height: 100%;
          }
        }

        &::after {
          border: none;
        }

        .van-field__control {
          text-align: center;
          font-size: 32px;

          &::-webkit-input-placeholder {
            text-align: center;
            font-size: 32px;
            color: #666666;
          }
        }
      }

      .submit {
        width: 502px;
        display: block;
        margin: 30px auto 0;
        animation: identifier 1s ease-in-out infinite alternate;

        @keyframes identifier {
          0% {
            transform: scale(1);
          }

          25% {
            transform: scale(0.9);
          }

          50% {
            transform: scale(1);
          }

          75% {
            transform: scale(0.9);
          }
        }
      }

      ::v-deep .van-checkbox {
        width: auto;
        justify-content: center;

        &__label {
          font-size: 24px;
          color: #ffe0cc;
        }
      }
    }
  }
}
</style>
